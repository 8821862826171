import { ref } from "vue";
import { defineStore } from "pinia";
import { useCookies } from "@vueuse/integrations/useCookies";

type GuestStoreData = {
  id: string | null;
  username: string | null;
};
export const useGuestStore = defineStore("guest", () => {
  const { set } = useCookies(["guestId", "guestUsername"]);
  const cookieGuestId = useCookie("guestId").value || null;
  const cookieGuestUsername = useCookie("guestUsername").value || null;

  const data = ref<GuestStoreData>({
    id: cookieGuestId,
    username: cookieGuestUsername,
  });

  const setGuestId = (id: string) => {
    data.value.id = id;
    set("guestId", id, {
      maxAge: 60 * 60 * 24 * 2,
      path: "/",
    });
  };
  const setGuestUsername = (username: string) => {
    data.value.username = username;
    set("guestUsername", username, {
      maxAge: 60 * 60 * 24 * 2,
      path: "/",
    });
  };

  const getGuestId = () => {
    if (data.value.id) {
      return data.value.id;
    }
    return useCookie("guestId").value;
  };

  const getGuestUsername = () => {
    if (data.value.username) {
      return data.value.username;
    }
    return useCookie("guestUsername").value;
  };

  const logoutGuest = () => {
    set("guestUsername", null);
    set("guestId", null);
    data.value.id = null;
    data.value.username = null;
  };

  return {
    data,
    setGuestId,
    setGuestUsername,
    getGuestId,
    getGuestUsername,
    logoutGuest,
  };
});
